import {
	Box,
	Button,
	makeStyles,
	Typography,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import {
	authMe2,
	getQrCodeImagemAction,
	loadPermissao,
	showQrCodeAction,
} from '../../actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { get } from 'lodash';

import { useLocation } from 'react-router-dom';

import { isMobile } from 'react-device-detect';
import ClipLoader from 'react-spinners/ClipLoader';
import { APP_CONFIG } from '../../constants/config';

const useStyles = makeStyles((theme) => ({
	homeRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		margin: '0px',
		padding: '0px',
		background: APP_CONFIG.mainCollors.primaryGradient,
	},
	pagesRoot: {
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		margin: '0px',
		padding: '0px',
		background: APP_CONFIG.mainCollors.primaryGradient,
	},
	homeContentContainer: {
		[theme.breakpoints.down('md')]: {
			padding: '40px 100px',
		},
		padding: '40px 300px',
	},
	smallHomeContentContainer: {},
	pagesContentContainer: {
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			padding: '40px 100px',
		},
		[theme.breakpoints.down(850)]: {
			padding: '40px 40px',
		},
		padding: '40px 250px',
	},
}));

const QrCodeCharge = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const small = useMediaQuery(theme.breakpoints.down(1090));
	const classes = useStyles();
	const history = useHistory();
	const qrcodeUrl = useSelector((state) => state.qrcodeUrl);
	const showQrCode = useSelector((state) => state.showQrCode);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const counter = useRef(0);
	const imageLoaded = () => {
		setLoading(false);
	};

	const { section, id, subsection } = useParams();
	let location = useLocation();
	let content = null;

	useEffect(() => {
		dispatch(getQrCodeImagemAction(id));
	}, [id]);

	useEffect(() => {
		dispatch(showQrCodeAction(id));
	}, [id]);

	return (
		<Box className={classes.pagesRoot}>
			<Box style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
				{!small ? (
					<img
						alt="Logo de fundo"
						src={APP_CONFIG.assets.backgroundLogo}
						style={{
							height: '400px',
							position: 'absolute',
							right: 0,
							bottom: 0,
						}}
					/>
				) : null}

				{/* {!matches ? (
					<img
						alt="Logo vbank"
						src={headerLogo}
						style={{
							position: 'absolute',
							left: 250,
							top: 10,
							cursor: 'pointer',
							height: '120px',
							zIndex: 3,
						}}
						onClick={() => history.push('/')}
					/>
				) : null} */}
			</Box>

			<Box
				style={{
					zIndex: 30,
					display: 'flex',
					alignSelf: 'center',
					marginTop: '40px',
					flexDirection: 'column',

					width: '50%',
					maxWidth: '500px',
					padding: '15px',
					borderRadius: 20,
				}}
			>
				<img
					className={classes.assets}
					alt="Logo"
					src={APP_CONFIG.assets.smallWhiteLogo}
					style={{
						marginTop: '10px',
						marginBottom: 20,
						width: '50%',
						maxWidth: '200px',
						alignSelf: 'center',
					}}
				/>
				<Typography
					variant="subtitle1"
					style={{
						fontSize: !small ? 30 : '1.1em',
						alignSelf: 'center',
						marginTop: '30px',
						fontWeight: 'bold',
						color: '#F0F0F0',
					}}
				>
					Pague @
					{showQrCode.conta && showQrCode.conta.nome
						? showQrCode.conta.nome
						: null}
				</Typography>
				{small ? (
					<Box
						style={{
							paddingTop: '10px',
							display: 'flex',
							flexDirection: 'column',
							alignSelf: 'center',
						}}
					>
						<a href={`itabank://itabank/qr-code/${id}`}>
							<Button
								variant="contained"
								style={{
									width: '100%',
									alignSelf: 'center',
									background: 'white',
									color: 'rgb(157, 156, 198)',
								}}
							>
								ABRIR APLICATIVO
							</Button>
							<Box
								style={{
									display: 'flex',
									alignSelf: 'center',
									flexDirection: 'column',
									marginTop: '20px',
									backgroundColor: 'white',
									width: '100%',
									maxWidth: '200px',
									borderRadius: 20,

									boxShadow: '0.5px 0.5px 0.5px 0.5px #9E9E9E',
								}}
							>
								<Box style={{ alignSelf: 'center', marginTop: '5px' }}>
									<ClipLoader
										/* color={color} */ loading={loading}
										/* css={override} */ size={100}
									/>
								</Box>
								<img
									onLoad={imageLoaded}
									alt="QrCode"
									src={qrcodeUrl}
									style={{
										marginTop: '15px',
										marginBottom: '15px',
										alignSelf: 'center',
										width: '70%',
										maxWidth: '250px',
									}}
								/>
							</Box>
						</a>
					</Box>
				) : (
					<>
						<Typography
							variant="subtitle1"
							style={{
								fontSize: !small ? 20 : '0.9em',
								marginTop: '20px',
								textAlign: 'center',
								marginLeft: 35,
								marginRight: 35,
								color: '#F0F0F0',
							}}
						>
							Abra o aplicativo do {APP_CONFIG.smallName} no celular e
							escaneie o Qr Code abaixo:{' '}
						</Typography>
						<Box
							style={{
								display: 'flex',
								alignSelf: 'center',
								flexDirection: 'column',
								marginTop: '20px',
								backgroundColor: 'white',
								width: '70%',
								maxWidth: '200px',
								borderRadius: 20,

								boxShadow: '0.5px 0.5px 0.5px 0.5px #9E9E9E',
							}}
						>
							<Box style={{ alignSelf: 'center', marginTop: '5px' }}>
								<ClipLoader
									style={{ alignSelf: 'center' }}
									/* color={color} */ loading={loading}
									/* css={override} */ size={100}
								/>
							</Box>
							<img
								onLoad={imageLoaded}
								alt="QrCode"
								src={qrcodeUrl}
								style={{
									marginTop: '15px',
									marginBottom: '15px',
									alignSelf: 'center',
									width: '70%',
									maxWidth: '250px',
								}}
							/>
						</Box>
					</>
				)}

				<Typography
					variant="subtitle1"
					style={{
						fontSize: !small ? 20 : '0.9em',
						marginTop: '20px',
						textAlign: 'center',
						marginLeft: 35,
						marginRight: 35,
						color: '#F0F0F0',
					}}
				>
					Não tem conta no {APP_CONFIG.smallName}?
				</Typography>

				<a
					href={APP_CONFIG.linkApp}
					style={{
						fontSize: !small ? 20 : '0.9em',
						color: '#ED757D',
						alignSelf: 'center',
					}}
				>
					{' '}
					Baixe o app gratuitamente
				</a>
			</Box>
		</Box>
	);
};

export default QrCodeCharge;
